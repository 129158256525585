import React from 'react';
import { Box, Center, Button, useDisclosure, Heading } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import ImageHover from '@components/Common/ImageHover';
import Content from './Content';
import txtWording from './locales';

import { IMG_PHOTO_1 } from '@/constants/assets';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST } from '@/constants';
import useLang from '@hooks/useLang';

const IMAGES = [IMG_PHOTO_1];

function PhotoSection() {
  const defaultLang = useLang();
  const lang = defaultLang;

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgPrimary" padding="32px 0 32px 0" textAlign="center">
      <Heading fontWeight="normal" color="bgSecondary" textTransform="uppercase" fontSize="md" letterSpacing="4px">
        Gallery of
      </Heading>
      <Heading
        fontWeight="normal"
        fontSize="3xl"
        textTransform="uppercase"
        letterSpacing="4px"
        color="bgSecondary"
      >
        {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &
      </Heading>
      <Heading
        fontWeight="normal"
        fontSize="3xl"
        marginTop="-8px"
        textTransform="uppercase"
        letterSpacing="4px"
        color="bgSecondary"
      >
        {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
      </Heading>
      <Center marginTop="40px">
        <Box>
          <ImageHover zIndex={1} images={IMAGES} imageProps={{ height: '100%', width: '300px' }} />
        </Box>
      </Center>

      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            {...DEFAULT_BUTTON_PROPS}
            size="sm"
            fontWeight="light"
            bgColor="bgAlternative"
            color="mainColorTextLight"
            fontSize="md"
            // textTransform="uppercase"
            fontStyle="italic"
            marginTop="20px"
            onClick={() => onOpen()}
          >
            {txtWording.btnGallery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export default React.memo(PhotoSection);
