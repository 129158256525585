import React from 'react';
import { bool, func } from 'prop-types';
import { Center, Box, Spinner, IconButton } from '@chakra-ui/react';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';
//import useInvitation from '@hooks/useInvitation';
import txtWording from './locales';
import useLang from '@hooks/useLang';
/**
 * function to render button scroll down
 * @returns {JSX.Element}
 */
function ScrollToDown({ loaded, loading, onClick, ...rest }) {
  //const isInvitation = useInvitation();
  //const text = isInvitation ? 'Open Bar (and Wedding) Invitation' : 'Wedding Announcement';
  const lang = useLang();

  if (loading) {
    return (
      <Center>
        <Spinner marginTop="16px" color="white" />
      </Center>
    );
  }

  return (
    <Box>
      <Box
        marginTop="8px"
        fontSize="sm"
        textAlign="center"
        color="secondaryColorText"
        letterSpacing="1px"
        fontStyle="italic"
        onClick={onClick}
        cursor="pointer"
        
      />        
      <Center>
        <IconButton
          {...rest}
          variant="outline"
          size="sm"
          className="animation-up-and-down"
          isRound
          icon={<HiOutlineChevronDoubleDown color="white" />}
          onClick={onClick}
          filter="drop-shadow(1px 1px 1px #352023)"
        />
      </Center>
      {!loaded && (
        <Box marginTop="8px" textAlign="center" color="white" fontSize='14px' filter="drop-shadow(1px 1px 1px #352023)" >
          {txtWording.buttonSubtitle[lang]}
        </Box>
      )}
    </Box>
  );
}

ScrollToDown.propTypes = {
  loaded: bool.isRequired,
  loading: bool.isRequired,
  onClick: func.isRequired,
};

export default React.memo(ScrollToDown);
